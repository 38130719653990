/**
 * Just view/template component, no model/data logic!
 */
import React from "react"
import Layout from "./../../layout"
import Img from 'gatsby-image'
import SEO from "./../../seo"

// s4n
// import "./../../assets/sass/templates/node/_blog.scss"
import classNames from 'classnames';
import LogoHeuresis from './../../../images/custom/heuresis-logo.svg'

const ComponentStateless = (props) => {
  const { data, translate: t } = props; // alias t === translate function

  // console.log(data.img.src)

  const CoverClass = classNames({
    'wp-block-cover has-background-dim': true,
    's4n-has-no-background': !data.img,
  });

  let styleCover = {
    'background-color': `rgba(0, 51, 153, 1)`,
    'background-position': `center`, // `49.4565% 0%`,
    'background-repeat': `no-repeat`,
  }

  let meta = [];

  if (data.img) {
    styleCover['background-image'] = `url('${data.img.src}')`;

    meta.push(
      {
        property: `image`,
        content: `${process.env.SOFT4NET_STATIC_BASE_URL}${data.img.src}`,
      }, 
      {
        property: `og:image`,
        content: `${process.env.SOFT4NET_STATIC_BASE_URL}${data.img.src}`,
      },
      {
        property: `twitter:image`,
        content: `${process.env.SOFT4NET_STATIC_BASE_URL}${data.img.src}`,
      }
    );
  }

  return (
    <Layout node={data.node}>
        <SEO 
          title={`Blog - ${data.title}`} 
          keywords={`blog`} 
          meta={meta} 
          nodeId={data.node.drupal_internal__nid} 
          field_metatag={data.node.field_metatag}
        />

        <div 
          className={CoverClass}
          // data-entity-type="file" data-entity-uuid="27469459-3f6a-497d-8a82-fd55a1065a67" data-image-style="original" 
          style={styleCover}
        >
          <div className="wp-block-cover__inner-container">
            <h1 className="text-center">{ data.title }</h1>
            <span className="posted_by d-block text-center text-white">{ data.created }</span>
            {/* <h2 className="has-text-align-center">Zapraszamy do współpracy</h2> */}
            {/* <p style="font-size: 24px; text-align: center;">Chcemy dzielić się naszym doświadczeniem, wiedzą,oraz wypracowanymi i sprawdzonymi rozwiązaniami i narzędziami. Szukamy osób indywidualnych i instytucji szkoleniowych do współpracy. Niezależnie od formy współpracy, stawiamy na jakość, skuteczność, troskę oraz uczciwość. </p> */}
          </div>
        </div>

        {/* <section id="blog-page" className="blog-page">
            <div className="container">
                <h1>{ data.title }</h1>
                <span className="posted_by">{ data.created }</span>
                <div style={{ maxWidth: `900px`, marginBottom: `1.45rem`, width: `100%` }}>{ data.img && <Img fluid={ data.img } /> }</div>
            </div>
        </section> */}

        {data.bodyValueEnchanced}
        {/* <div dangerouslySetInnerHTML={{ __html: data.bodyValue }}></div> */}

        {/* { t({ id: "soft4net_deploy_build_time" }) } */}
    </Layout>
  )
}

export default ComponentStateless